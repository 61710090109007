<template>
    <b-row>
        <b-col cols="12" md="12" v-if="isLoading">
            <b-card class="mt-2">
                <div class="text-center my-2">
                    <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                    <strong>Loading, please wait...</strong>
                </div>
            </b-card>
        </b-col>
        <b-col cols="12" md="8">
            <b-card v-if="appData" class="appDataOverflow">
                <div>
                    <div class="d-flex align-items-center justify-content-between" id="mobileHeader">
                        <div class="d-flex align-items-center">
                            <b-media vertical-align="center">
                                <template #aside>
                                    <b-avatar rounded="sm" size="50" :src="backend_url+appData.icon"
                                              :text="avatarText(appData.name)"
                                              :variant="`light-secondary`"/>
                                </template>
                            </b-media>
                            <div class="info">
                                <h3>[{{ appData.code }}] {{ appData.name }}
                                </h3>
                                <app-detail-status :appData="appData"></app-detail-status>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <b-button v-if="appData.repo" :href="'https://bitbucket.org/gameguru/'+appData.repo"
                                      target="_blank" variant="relief-info" class="bg-bitbucket mr-1">Bitbucket
                            </b-button>
                            <b-button v-if="appData.asana_id" :href="'https://app.asana.com/0/'+appData.asana_id"
                                      target="_blank" variant="relief-danger" class="bg-asana mr-1">Asana
                            </b-button>

                            <b-button class="button-secondary mr-1" variant="relief-secondary"
                                      v-if="$can('read', 'appEdit') && (appData.processing_status === 'error' || appData.processing_status === 'completed')"
                                      :to="{ name: appData.automation ? 'app-edit' : 'app-edit-manual', params: { app: appData.id } }"
                                      v-b-tooltip.hover.top="'Edit App'">
                                <feather-icon icon="EditIcon" size="14"/>
                            </b-button>
                        </div>
                    </div>
                    <hr/>
                    <div class="mt-2">
                        <div class="mb-1 pl-1 pr-1">
                            <b-row>
                                <b-col cols="12" md="3" class="mb-1" v-if="appData.team">
                                    <div>
                                        <div class="d-flex align-items-center mb-25">
                                            <h6 class="mb-0">Team:</h6>
                                        </div>
                                        <p class="m-0">{{ appData.team.name }}</p>
                                    </div>
                                </b-col>
                                <b-col cols="12" md="3" class="mb-1">
                                    <div>
                                        <div class="d-flex align-items-center mb-25">
                                            <h6 class="mb-0">Facebook ID:</h6>
                                        </div>
                                        <p class="m-0">{{ appData.facebook_id }}</p>
                                    </div>
                                </b-col>
                                <b-col cols="12" md="3" class="mb-1" v-if="appData.facebook_client_token">
                                  <div>
                                    <div class="d-flex align-items-center mb-25">
                                      <h6 class="mb-0">Facebook Client Token:</h6>
                                    </div>
                                    <p class="m-0">{{ appData.facebook_client_token }}</p>
                                  </div>
                                </b-col>
                                <b-col cols="12" md="3" class="mb-1" v-if="appData.snapchat_id">
                                    <div>
                                        <div class="d-flex align-items-center mb-25">
                                            <h6 class="mb-0">Snapchat ID:</h6>
                                        </div>
                                        <p class="m-0">{{ appData.snapchat_id }}</p>
                                    </div>
                                </b-col>
                                <b-col cols="12" md="3" class="mb-1" v-if="appData.tiktok_account_name">
                                    <div>
                                        <div class="d-flex align-items-center mb-25">
                                            <h6 class="mb-0">Tiktok Account:</h6>
                                        </div>
                                        <p class="m-0">{{ appData.tiktok_account_name }}</p>
                                    </div>
                                </b-col>
                                <b-col cols="12" md="3" class="mb-1" v-if="appData.elephant_game_id">
                                  <div>
                                    <div class="d-flex align-items-center mb-25">
                                      <h6 class="mb-0">Elephant Game ID:</h6>
                                    </div>
                                    <p class="m-0">{{ appData.elephant_game_id }}</p>
                                  </div>
                                </b-col>
                                <b-col cols="12" md="3" class="mb-1" v-if="appData.elephant_game_secret">
                                  <div>
                                    <div class="d-flex align-items-center mb-25">
                                      <h6 class="mb-0">Elephant Game Secret:</h6>
                                    </div>
                                    <p class="m-0">{{ appData.elephant_game_secret }}</p>
                                  </div>
                                </b-col>
                                <b-col cols="12" md="3" v-if="!appData.automation && appData.has_ios">
                                    <div>
                                        <div class="d-flex align-items-center mb-25">
                                            <h6 class="mb-0">Provision File</h6>
                                        </div>
                                        <p class="m-0">
                                            <b-badge v-if="appData.provision_val" variant="light-success">
                                                <feather-icon icon="EyeIcon" class="mr-25"/>
                                                <span>Loaded</span>
                                            </b-badge>
                                            <b-badge v-else variant="light-secondary">
                                                <feather-icon icon="XIcon" class="mr-25"/>
                                                <span>Not Installed</span>
                                            </b-badge>
                                        </p>
                                    </div>
                                </b-col>
                                <b-col cols="12" md="3" v-if="!appData.automation && appData.has_ios">
                                    <div>
                                        <div class="d-flex align-items-center mb-25">
                                            <h6 class="mb-0">Certificate File</h6>
                                        </div>
                                        <p class="m-0">
                                            <b-badge v-if="appData.certificate_val" variant="light-success">
                                                <feather-icon icon="EyeIcon" class="mr-25"/>
                                                <span>Loaded</span>
                                            </b-badge>
                                            <b-badge v-else variant="light-secondary">
                                                <feather-icon icon="XIcon" class="mr-25"/>
                                                <span>Not Installed</span>
                                            </b-badge>
                                        </p>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div v-if="appData.has_ios">
                            <div class="mb-25">
                                <b-badge variant="light-secondary d-flex align-items-center mb-1 badge-gray">
                                    <social-icons :socialType="'ios'" :size="'14'"></social-icons>
                                    <h6 class="mb-0 ml-5px">iOS Shortcut:</h6>
                                </b-badge>
                            </div>
                            <div class="pl-1 pr-1">
                                <b-row>
                                    <b-col cols="12" md="3" v-if="appData.appstore_account_id">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Appstore Account</h6>
                                            <p class="m-0">{{ appData.appstore_account_name }}</p>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="3" v-if="appData.appstore_id">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Appstore ID</h6>
                                            <b-link class="m-0"
                                                    :href="'https://apps.apple.com/us/app/abc/id'+appData.appstore_id"
                                                    target="_blank">{{ appData.appstore_id }}
                                            </b-link>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="3" v-if="appData.bundle">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Bundle</h6>
                                            <p class="m-0">{{ appData.bundle }}</p>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="3" v-if="appData.tiktok_ios_id">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Tiktok ID</h6>
                                            <p class="m-0">{{ appData.tiktok_ios_id }}</p>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="3" v-if="appData.adjust_ios_token">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Adjust</h6>
                                            <b-link class="m-0"
                                                    :href="'https://dash.adjust.com/#/statistics/overview/default/'+appData.adjust_ios_token"
                                                    target="_blank">{{ appData.adjust_ios_token }}
                                            </b-link>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="3" v-if="appData.applovin_ios_inter">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Applovin Inter</h6>
                                            <b-link class="m-0"
                                                    :href="'https://dash.applovin.com/o/mediation/ad_units/edit/356568/'+appData.applovin_ios_inter"
                                                    target="_blank">{{ appData.applovin_ios_inter }}
                                            </b-link>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="3" v-if="appData.applovin_ios_reward">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Applovin Reward</h6>
                                            <b-link class="m-0"
                                                    :href="'https://dash.applovin.com/o/mediation/ad_units/edit/356568/'+appData.applovin_ios_reward"
                                                    target="_blank">{{ appData.applovin_ios_reward }}
                                            </b-link>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="3" v-if="appData.applovin_ios_banner">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Applovin Banner</h6>
                                            <b-link class="m-0"
                                                    :href="'https://dash.applovin.com/o/mediation/ad_units/edit/356568/'+appData.applovin_ios_banner"
                                                    target="_blank">{{ appData.applovin_ios_banner }}
                                            </b-link>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="3" v-if="appData.admob_ios_app_id">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Admob App ID</h6>
                                            <p class="m-0">{{ appData.admob_ios_app_id }}</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        <div v-if="appData.has_android">
                            <div class="mb-25">
                                <b-badge variant="light-secondary d-flex align-items-center mb-1 badge-gray">
                                    <social-icons :socialType="'android'" :size="'14'"></social-icons>
                                    <h6 class="mb-0 ml-5px">Android Shortcut:</h6>
                                </b-badge>
                            </div>
                            <div class="pl-1 pr-1">
                                <b-row>
                                    <b-col cols="12" md="3" v-if="appData.android_account_id">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Android Account</h6>
                                            <p class="m-0">{{ appData.google_account_name }}</p>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="3" v-if="appData.package_name">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Package Name</h6>
                                            <p class="m-0">{{ appData.package_name }}</p>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="3" v-if="appData.tiktok_android_id">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Tiktok ID</h6>
                                            <p class="m-0">{{ appData.tiktok_android_id }}</p>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="3" v-if="appData.adjust_android_token">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Adjust</h6>
                                            <b-link
                                                :href="'https://dash.adjust.com/#/statistics/overview/default/'+appData.adjust_android_token"
                                                target="_blank" class="m-0">{{ appData.adjust_android_token }}
                                            </b-link>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="3" v-if="appData.applovin_android_inter">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Applovin Inter</h6>
                                            <b-link class="m-0"
                                                    :href="'https://dash.applovin.com/o/mediation/ad_units/edit/356568/'+appData.applovin_android_inter"
                                                    target="_blank">{{ appData.applovin_android_inter }}
                                            </b-link>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="3" v-if="appData.applovin_android_reward">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Applovin Reward</h6>
                                            <b-link class="m-0"
                                                    :href="'https://dash.applovin.com/o/mediation/ad_units/edit/356568/'+appData.applovin_android_reward"
                                                    target="_blank">{{ appData.applovin_android_reward }}
                                            </b-link>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="3" v-if="appData.applovin_android_banner">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Applovin Banner</h6>
                                            <b-link class="m-0"
                                                    :href="'https://dash.applovin.com/o/mediation/ad_units/edit/356568/'+appData.applovin_android_banner"
                                                    target="_blank">{{ appData.applovin_android_banner }}
                                            </b-link>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="3" v-if="appData.admob_android_app_id">
                                        <div class="mb-1">
                                            <h6 class="mb-0">Admob App ID</h6>
                                            <p class="m-0">{{ appData.admob_android_app_id }}</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>

                        <div
                            v-if="appData.developers.length || appData.threeDArtists.length || appData.productManagers.length || appData.releaseBuildPermissionMembers.length">
                            <div class="mb-25">
                                <b-badge variant="light-secondary d-flex align-items-center mb-1 badge-gray">
                                    <feather-icon icon="UsersIcon" size="15"></feather-icon>
                                    <h6 class="mb-0 ml-5px">Members</h6>
                                </b-badge>
                            </div>
                            <div class="pl-1 pr-1">
                                <b-row>
                                    <b-col cols="12" md="4" v-if="appData.productManagers.length">
                                        <div class="mb-1">
                                            <h6 class="mb-25">Product Manager</h6>
                                            <p class="m-0">
                                                <b-avatar v-for="pm in appData.productManagers" :key="pm.key"
                                                          class="pull-up mr-25 cursor-pointer" :src="pm.image"
                                                          rounded="sm" size="24px"
                                                          :text="avatarText(pm.label)"
                                                          v-b-tooltip.html.hover.top="pm.label +'<br/>Bitbucket:'+ (pm.bitbucket?'✓':'☓')+'<br/>Asana:'+ (pm.asana?'✓':'☓')+(pm.delete_status?'<br/>Delete:Waiting':'')"
                                                          :badge="pm.bitbucket && pm.asana ?'✓':(pm.delete?'X':'')"
                                                          :badge-variant="pm.bitbucket?'light-success':'light-warning'"/>
                                            </p>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="4" v-if="appData.developers.length">
                                        <div class="mb-1">
                                            <h6 class="mb-25">Developer</h6>
                                            <p class="m-0">
                                                <b-avatar v-for="dev in appData.developers" :key="dev.key"
                                                          class="pull-up mr-25 cursor-pointer"
                                                          :src="dev.image" rounded="sm" size="24px"
                                                          :text="avatarText(dev.label)"
                                                          v-b-tooltip.html.hover.top="dev.label +'<br/>Bitbucket:'+ (dev.bitbucket?'✓':'☓')+'<br/>Asana:'+ (dev.asana?'✓':'☓')+(dev.delete_status?'<br/>Delete:Waiting':'')"
                                                          :badge="dev.bitbucket && dev.asana?'✓':(dev.delete?'X':'')"
                                                          :badge-variant="dev.bitbucket?'light-success':'light-warning'"/>
                                            </p>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="4" v-if="appData.threeDArtists.length">
                                        <div class="mb-1">
                                            <h6 class="mb-25">3D Artist</h6>
                                            <p class="m-0">
                                                <b-avatar v-for="t3artist in appData.threeDArtists" :key="t3artist.key"
                                                          class="pull-up mr-25 cursor-pointer" :src="t3artist.image"
                                                          rounded="sm" size="24px"
                                                          :text="avatarText(t3artist.label)"
                                                          v-b-tooltip.html.hover.top="t3artist.label +'<br/>Bitbucket:'+ (t3artist.bitbucket?'✓':'X')+'<br/>Asana:'+ (t3artist.asana?'✓':'☓')+(t3artist.delete_status?'<br/>Delete:Waiting':'')"
                                                          :badge="t3artist.bitbucket && t3artist.asana?'✓':(t3artist.delete?'X':'')"
                                                          :badge-variant="t3artist.bitbucket?'light-success':'light-warning'"/>
                                            </p>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="4" v-if="appData.releaseBuildPermissionMembers.length">
                                        <div class="mb-1">
                                            <h6 class="mb-25">Release Build Permission</h6>
                                            <p class="m-0">
                                                <b-avatar v-for="releaseBuildPermissionMember in appData.releaseBuildPermissionMembers" :key="releaseBuildPermissionMember.key"
                                                          class="pull-up mr-25 cursor-pointer" :src="releaseBuildPermissionMember.image"
                                                          v-b-tooltip.html.hover.top="releaseBuildPermissionMember.label"
                                                          rounded="sm" size="24px"
                                                          :text="avatarText(releaseBuildPermissionMember.label)"
                                                          />
                                            </p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </div>
                </div>
            </b-card>
            <b-row>


                <b-col cols="12" md="12" v-if="appData &&  appData.unity_events">
                    <b-card class="appDataOverflow card-app-design">
                        <b-media no-body class="mb-1">
                            <b-media-body class="my-auto">
                                <h6 class="mb-0">
                                    Unity Tracker
                                </h6>
                                <small>[{{ appData.code }}] - {{ appData.name }}</small>
                            </b-media-body>
                            <div class="design-planning-wrapper float-right" v-if="appData.unity_events.events">
                                <div class="design-planning mb-0">
                                    <p class="card-text mb-25">
                                        Total Score
                                    </p>
                                    <h6 class="mb-0">
                                        {{ appData.unity_events.total_score }}
                                    </h6>
                                </div>
                            </div>
                        </b-media>
                        <div class="mt-1 text-left" v-if="appData.unity_events">
                            <hr>
                            <div id="appDetailHeight">
                                <b-table :items="appData.unity_events.events" responsive class="mb-0"
                                         :fields="unityEventColumns"
                                         :table-class="'table dataTable no-footer dtr-column '">
                                    <template #cell(user)="data">
                                        <b-media no-body class="mb-50">
                                            <b-media-aside class="mr-75 my-auto">
                                                <b-avatar size="34" :src="data.item.user.image"
                                                          :text="avatarText(data.item.user.name)"/>
                                            </b-media-aside>
                                            <b-media-body class="my-auto">
                                                <h6 class="mb-0">
                                                    {{ data.item.user.name }}
                                                </h6>
                                                <small>{{ data.item.date }}</small>
                                            </b-media-body>
                                        </b-media>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </b-card>
                </b-col>


                <b-col cols="12" md="12" v-if="appData && appData.builds.length > 0">
                    <b-card class="appDataOverflow card-app-design">
                        <b-media no-body class="mb-1">
                            <b-media-body class="my-auto">
                                <h6 class="mb-0">
                                    Build List
                                </h6>
                                <small>[{{ appData.code }}] - {{ appData.name }}</small>
                            </b-media-body>
                            <div class="design-planning-wrapper float-right" v-if="appData.repository.commits">
                                <div class="design-planning mb-0">
                                    <p class="card-text mb-25">
                                        Total Build
                                    </p>
                                    <h6 class="mb-0">
                                        {{ appData.builds.length }}
                                    </h6>
                                </div>
                            </div>
                        </b-media>
                        <div class="mt-1 text-left" v-if="appData.builds.length > 0">
                            <hr>
                            <div id="appDetailHeight">
                                <b-table :items="appData.builds" responsive class="mb-0" :fields="buildColumns"
                                         :table-class="'table dataTable no-footer dtr-column '">
                                    <template #cell(id)="data">
                                        <span class="align-text-top">{{ data.item.id }}</span>
                                    </template>
                                    <template #cell(user)="data">
                                        <span class="align-text-top">{{ data.item.user.name }}</span>
                                    </template>
                                    <template #cell(OS)="data">
                                        <social-icons :socialType="data.item.os" :size="'18'"></social-icons>
                                    </template>
                                    <template #cell(debug)="data">
										<span class="align-text-top text-capitalize">
											<b-badge variant="light-success" v-if="data.item.debug == 0">Release
											</b-badge>
											<b-badge variant="light-warning" v-else>Debug</b-badge>
											<b-badge variant="light-success ml-25" v-if="data.item.aab == 1">aab
											</b-badge>
										</span>
                                    </template>
                                    <template #cell(Status)="data">
										<span class="align-text-top text-capitalize">
											<b-badge title="Succeeded" class="bg-light-success"
                                                     v-if="data.item.status == 'succeeded'">
												<feather-icon icon="CheckIcon" size="14"/>
											</b-badge>
											<b-badge title="Waiting" class="bg-light-warning"
                                                     v-if="data.item.status == 'waiting'">
												<feather-icon icon="ClockIcon" size="14"/>
											</b-badge>
											<b-badge title="In Progress" class="bg-light-info"
                                                     v-if="data.item.status == 'inprogress'">
												<feather-icon icon="PlayIcon" size="14"/>
											</b-badge>
											<b-badge title="Failed" class="bg-light-danger"
                                                     v-if="data.item.status == 'failed'">
												<feather-icon icon="XIcon" size="14"/>
											</b-badge>
										</span>
                                    </template>
                                    <template #cell(show)="data">
                                        <b-link class="mr-1" target="_blank"
                                                :to="{ name: 'build-detail', params: { build: data.item.id } }"
                                                v-b-tooltip.hover.top="'View Build Logs'">
                                            <feather-icon icon="ActivityIcon" size="18"/>
                                        </b-link>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </b-card>
                </b-col>
                <b-col cols="12" md="12" v-if="appData && appData.repository.commits.length > 0">
                    <b-card class="appDataOverflow card-app-design">
                        <b-media no-body class="mb-1">
                            <b-media-body class="my-auto">
                                <h6 class="mb-0">
                                    Commit List
                                </h6>
                                <small>{{ appData.repo }}</small>
                            </b-media-body>
                            <div class="design-planning-wrapper float-right" v-if="appData.repository.commits">
                                <div class="design-planning mb-0">
                                    <p class="card-text mb-25">
                                        Total Commit
                                    </p>
                                    <h6 class="mb-0">
                                        {{ appData.repository.commits.length }}
                                    </h6>
                                </div>
                            </div>
                        </b-media>
                        <div class="mt-1 text-left" v-if="appData.repository.commits.length > 0">
                            <hr>
                            <div id="appDetailHeight">
                                <app-timeline>
                                    <app-timeline-item variant="success" v-for="commit in appData.repository.commits"
                                                       :key="commit.id" class="pb-2 pl-2" style="line-break: anywhere;">
                                        <div
                                            class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-50">
                                            <h6 class="mb-0"> {{ commit.user }}</h6>
                                            <small
                                                class="timeline-item-time text-nowrap text-muted ml-1">{{
                                                    dateFormat(commit.date, 'YYYY-MM-DD HH:mm')
                                                }}</small>
                                        </div>
                                        <p class="mb-0">
                                            {{ commit.message }}
                                            <span class="links d-block mt-25">
												<a v-if="commit.link" class="d-inline mr-25" :href="commit.link"
                                                   target="_blank" rel="noreferrer noopener">
													<b-badge variant="light-secondary">Asana
														<feather-icon icon="ExternalLinkIcon"/>
													</b-badge>
												</a>
												<a class="d-inline"
                                                   :href="'https://bitbucket.org/gameguru/'+commit.repoName+'/commits/'+commit.hash"
                                                   target="_blank" rel="noreferrer noopener">
													<b-badge variant="light-secondary">Bitbucket
														<feather-icon icon="GitCommitIcon"/>
													</b-badge>
												</a>
											</span>
                                        </p>
                                    </app-timeline-item>
                                </app-timeline>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12" md="4">
            <b-card v-if="!isLoading && appData.logs && appData.logs.length>0" title="App Logs">
                <div class="text-left my-2">
                    <app-log-and-version-detail :appData="appData"></app-log-and-version-detail>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import useAppApi from '@/composables/useAppApi'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import moment from 'moment'
import AppDetailStatus from '@/components/AppDetailStatus.vue'
import AppLogAndVersionDetail from '@/components/AppLogAndVersionDetail.vue'
import SocialIcons from '@/components/SocialIcons.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {avatarText} from '@core/utils/filter'
import {
    BCard,
    BCardText,
    BBadge,
    BImg,
    BAlert,
    BButton,
    BRow,
    BCol,
    BSpinner,
    BLink,
    BEmbed,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BTable,
    VBTooltip,
    BAvatarGroup,
} from 'bootstrap-vue'

export default {
    components: {
        BEmbed,
        BLink,
        BSpinner,
        BCard,
        BCardText,
        BBadge,
        BImg,
        BAlert,
        BButton,
        BRow,
        BCol,
        BAvatar,
        BMedia,
        BMediaAside,
        BMediaBody,
        BCardBody,
        BCardTitle,
        BCardHeader,
        AppDetailStatus,
        AppLogAndVersionDetail,
        SocialIcons,
        AppTimeline,
        AppTimelineItem,
        BTable,
        VBTooltip,
        BAvatarGroup
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            backend_url: process.env.VUE_APP_BACKEND_URL
        }
    },
    mounted() {
        this.fetchApp(router.currentRoute.params.app)
    },
    setup() {
        const buildColumns = [
            {key: 'id', sortable: false, label: "Build"},
            {key: 'user', sortable: false},
            {key: 'OS', sortable: false},
            {key: 'debug', sortable: false, label: "Type"},
            {key: 'editor_version', sortable: false, label: 'Unity Version'},
            {label: 'Version', sortable: false, key: 'bundle_version'},
            {key: 'Status', sortable: false},
            {label: 'Time', sortable: false, key: 'created_at'},
            {key: 'show', label: '#', sortable: false},
        ]
        const unityEventColumns = [
            {key: 'user', sortable: false},
            {key: 'script_reload', sortable: false},
            {key: 'project_changed', sortable: false},
            {key: 'hierarchy_changed', sortable: false},
            {key: 'asset_saved', sortable: false},
            {key: 'total_score', sortable: false}
        ]
        const {
            appData,
            fetchApp,
            isLoading,
        } = useAppApi()

        return {
            unityEventColumns,
            appData,
            fetchApp,
            isLoading,
            buildColumns,
            avatarText
        }
    },
    methods: {
        dateFormat(date, format) {
            return moment(date).format(format)
        },
    }
}
</script>
<style lang="scss">
.bg-bitbucket {
    background: #2684ff !important;

    &:hover {
        opacity: .8;
    }
}

.bg-asana {
    background: #f06a6a !important;

    &:hover {
        opacity: .8;
    }
}

.bg-adjust {
    background: #232323 !important;

    &:hover {
        opacity: .8;
    }
}

.bg-applovin {
    background: #333333 !important;

    &:hover {
        opacity: .8;
    }
}

@media (max-width: 996px) {
    #mobileHeader {
        display: block !important;
    }

    #mobileButtons {
        margin-top: 10px;
        display: inline-block !important;

    }
    #mobileButtons .btn {
        margin-bottom: 5px;
    }
}

#appDetailHeight::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

#appDetailHeight::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}

#appDetailHeight::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 100px;
}

#appDetailHeight {
    overflow: auto;
    max-height: 400px;
    padding-right: 10px;
    padding-top: 10px;
}

#appDetailHeight .timeline-item-point {
    top: 3px;
}

.ml-5px {
    margin-left: 5px !important;
}

.badge-gray {
    background-color: rgb(248 248 248) !important;
}

.tooltip-inner {
    text-align: left;
}
</style>
